import React from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { Topbar } from "app/common/Topbar";
import { config } from "app/common/config";

export function SuccessView() {
  const url = "#!/contact";
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid item>
        <Topbar title={"Reservation"} />
      </Grid>
      <Grid item sx={{ py: '130px' }}>
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {config.reservationSubmitMessage} — <strong><a href={url}>Contact restaurant for any further
            assistence.</a></strong>
        </Alert>
      </Grid>
    </Grid>
  )
}

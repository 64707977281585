export default class Order {
  constructor(orderSvc, config) {
    this.orderSvc = orderSvc;
    this.config = config;
    
    this.lines = [];
    this.id = "";
    this.discountLabel = "";
    this.subTotal = 0;
    this.total = 0;
    this.deliveryCharge = 0;
    this.totalDiscount = 0;
    this.totalItem = 0;
    this.instruction = "";
    this.errors = [];
    this.isServicesClosed = false;
    this.isStoreOpen = true;
    this.allowPreOrder = true;
    this.needPostCode = false;
    this.postCode = '';
  }
  
  reset(order) {
    this.id = order.id;
    this.subTotal = order.subTotal;
    this.totalDiscount = order.totalDiscount;
    this.deliveryCharge = order.deliveryCharge;
    this.total = order.total;
    this.discountLabel = order.discountLabel;
    this.orderType = order.orderType;
    this.totalItem = order.totalItem;
    this.lines = order.lines;
    this.instruction = order.instruction;
    this.orderTypes = order.orderTypes;
    this.errors = [].concat(order.errors);
    this.isStoreOpen = order.isStoreOpen;
    this.isServicesClosed = order.isServicesClosed;
    this.allowPreOrder = order.allowPreOrder;
    this.needPostCode = order.needPostCode;
    this.postCode = order.postCode;
    return this;
  }
  
  addLine(menuVm, qty, modifiers) {
    const menu = menuVm.hasSubItem ? menuVm.selectedSubItem :menuVm;
    const quantity = parseInt(qty, 10);
    
    return this.orderSvc.addLine({
      orderId: this.id,
      itemId: menu.id,
      quantity: quantity,
      modifiers: (modifiers && modifiers.length > 0) ? modifiers.map(x => x.id) : []
    }).then((order) => this.reset(order));
  }
  
  refresh() {
    this.orderSvc.getDraftOrder()
      .then(order => this.reset(order));
  }
  
  changeType() {
    this.orderSvc.update({
      orderId: this.id,
      orderType: this.orderType
    }).then((order) => this.reset(order));
  }
  
  removeModifier(line, modifier) {
    return this.orderSvc.removeModifier({
      orderId: this.id,
      lineId: line.id,
      lineModifierId: modifier.id
    }).then((order) => this.reset(order));
  }

  removeLine(line) {
    return this.orderSvc.removeLine({
      orderId: this.id,
      lineId: line.id,
    }).then((order) => this.reset(order));
  }

  reduceQuantity(line) {
    return this.orderSvc.reduceQuantity({
      orderId: this.id,
      lineId: line.id,
      quantity: 1
    }).then((order) => this.reset(order));
  }
}

Order.$inject = [
  "order.orderService",
  "common.config",
];

import React, { useEffect, useState } from 'react';
import { Grid, Alert, AlertTitle } from '@mui/material';
import axios from "app/common/axios";
import { config } from "app/common/config";
import { useMutation, } from 'react-query';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

const upload = (formData) => axios.post(`${config.apiUrl}/stores/default/address/upload`, formData);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const eventName = "address-import-completed";

export default function AddressTab({ tabValue }) {
  if (tabValue !== 5) {
    return null;
  }

  const [op, setState] = useState({ state: "notInitiated" });

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(upload, {
    onSuccess: () => setState(prevState => ({ ...prevState, state: "importing" }))
  });

  const handler = () => {
    setState(prevState => ({ ...prevState, state: "imported" }));
  };

  useEffect(() => {
    const channel = window.pusher.subscribe(config.pusherChannel);
    channel.bind(eventName, handler);
    return () => channel.unbind(eventName, handler);
  }, []);


  const onFileUpload = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    if (!file) { return; }

    const formData = new FormData();
    formData.append('file', file);
    mutate(formData);
  };

  const buttonLoading = () => {
    if (isLoading) {
      return true;
    }
    if (op.state === "importing") {
      return true;
    }
    return false;
  };

  const buttonLabel = () => {
    if (isLoading) {
      return "Uploading...";
    }
    if (op.state === "importing") {
      return "Importing...";
    }
    return "Upload CSV";
  };

  const showMessage = () => {
    if (op.state === "imported") {
      return (
        <Alert severity="success">
          <AlertTitle>Import</AlertTitle>
          Address imported succesfully!
        </Alert>
      );
    }

    if (isError) {
      return (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Could not upload address file � <strong>{error?.message}</strong>
        </Alert>
      );
    }

    if (isSuccess) {
      return (
        <Alert severity="info">
          <AlertTitle>Upload</AlertTitle>
          File uploaded successfully - <strong>wait for the import to complete...</strong>
        </Alert>
      );
    }

  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {showMessage()}
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          sx={{ mt: '10px', mb: '10px' }}
          color="primary"
          loadingPosition="start"
          component="label"
          variant="contained"
          loading={buttonLoading()}
          startIcon={<CloudUploadIcon />}>
            {buttonLabel()}
          <VisuallyHiddenInput type="file" accept=".csv" onChange={onFileUpload} />
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect } from "react";

import {
  Grid,
  TextField,
  Stack,
  Box,
  Alert
} from "@mui/material";

import "./Reservation.css";

//import { Image } from 'mui-image';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton, DatePicker } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import axios from "app/common/axios";
import dayjs from "app/common/dayjs";
import { config } from "app/common/config";
import { Topbar } from "app/common/Topbar";

const getSlots = (date) => axios.get(`${config.apiUrl}/stores/default/reservation/calendar/${date}/slots`).then(res => res.data.slots);
const getBlacklist = () => axios.get(`${config.apiUrl}/stores/default/reservation/blacklist`).then(res => res.data.blacklist);
const saveReservation = (payload) => axios.post(`${config.apiUrl}/reservations`, payload).then(res => res.data);

const defaultForm = {
  date: dayjs().endOf('date'),
  name: "",
  email: "",
  time: "",
  contactNo: "",
  notes: "",
  guests: 1,
};


export function ReservationView({ $state }) {
  const { handleSubmit, control, watch, formState: { errors }, setValue } = useForm({
    defaultValues: defaultForm
  });

  const [slots, setSlots] = useState([]);
  const [blacklist, setBlacklist] = useState([]);
  const [minDate, setMinDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const populateSlots = (dt) => {
    getSlots(dt.unix())
      .then(slots => {
        setSlots(slots);
        if (slots.length) {
          setValue('time', slots[0]);
        }
      });
  };

  const loadBlacklist = () =>
    getBlacklist()
      .then(bl => bl.map(x => dayjs(x, "YYYY-MM-DD")));

  const createTzDate = (dt) => dayjs.tz(dayjs(dt).endOf('date').format('DD-MM-YYYY'), "DD-MM-YYYY", config.ianaTimezone).endOf('date');

  const getFirstOpenDay = (bl) => {
    let day = dayjs();

    while (bl.some(dd => day.isSame(dd, 'day'))) {
      day = day.add(1, 'day');
    }
    return day;
  };

  useEffect(() => {
    loadBlacklist()
      .then((bl) => {
        setBlacklist(bl);
        const fod = getFirstOpenDay(bl);
        setMinDate(fod);
        setValue('date', fod);
        populateSlots(createTzDate(fod));
      });
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'date' && type === 'change') {
        setValue('time', '');
        populateSlots(createTzDate(value.date));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const shouldDisableDate = (date) => {
    return blacklist.some(dd => dayjs(date).isSame(dd, 'day'));
  };

  const onSubmit = data => {
    setLoading(true);

    const [time, modifier] = data.time.split(' ');
    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    minutes = parseInt(minutes, 10);

    let date = dayjs(data.date).startOf('date').format('YYYY-MM-DD');

    let rd = dayjs.tz(`${date} ${hours}:${minutes}:00`, config.ianaTimezone).utc().format();

    const req = {
      reservationDate: rd,
      name: data.name,
      contactNo: data.contactNo,
      guest: data.guests,
      email: data.email,
      note: data.notes
    };

    saveReservation(req)
      .then(() => $state.go('app.reservationSuccess'))
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <Topbar title="Reservation" />
      <Box sx={{ overflowY: 'auto' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2} sx={{ p: '10px' }}>
            {config.reservationHeaderMessage && (
              <Alert variant="outlined" severity="info">
                {config.reservationHeaderMessage}
              </Alert>
            )}

            {!config.isReservationActive && (
              <Alert variant="outlined" severity="warning">
                {config.reservationClosedMessage}
              </Alert>
            )}

            <Controller
              name="date"
              control={control}
              rules={{
                required: "Date is required"
              }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Date"
                  minDate={minDate}
                  views={['day']}
                  shouldDisableDate={shouldDisableDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.date}
                      helperText={errors.date?.message}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="time"
              control={control}
              rules={{
                required: "Time is required"
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Time"
                  SelectProps={{ native: true }}
                  error={!!errors.time}
                  helperText={errors.time?.message}
                >
                  {slots.map((slot) => (
                    <option key={slot} value={slot}>{slot}</option>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name="name"
              control={control}
              rules={{ required: "Name is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={"Name"}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />

            <Controller
              name="contactNo"
              control={control}
              rules={{
                required: "Contact no is required",
                minLength: { value: 10, message: "Number is too short" },
                maxLength: { value: 15, message: "Number is too long" },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="tel"
                  label={"Contact No"}
                  error={!!errors.contactNo}
                  helperText={errors.contactNo?.message}
                />
              )}
            />

            <Controller
              name="guests"
              control={control}
              rules={{
                required: "Guest is required",
                min: { value: 1, message: "At least one guest required" }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label={"No of Guests"}
                  error={!!errors.guests}
                  helperText={errors.guests?.message}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: { value: /^\S+@\S+$/i, message: 'Must be a valid email address' }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="email"
                  label={"Email"}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />

            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={3}
                  label={"Notes"}
                />
              )}
            />
            <LoadingButton
              sx={{ mt: '10px', mb: '10px' }}
              color="primary"
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveOutlinedIcon />}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </React.Fragment>
  );
}

import React from 'react';

import { Grid, TextField, Button } from '@mui/material';
import { Controller } from 'react-hook-form';
import TimezoneSelect from './TimezoneSelect';

const GeneralSettingsTab = ({ tabValue, control, data, errors, isSaving, isSuccess, message }) => {
  if (tabValue !== 1) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TimezoneSelect
          control={control}
          timezone={data.timezone}
          timezones={data.timezones}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="storeCloseMessage"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Store Close Message"
              fullWidth
              helperText="Specify what message you want customers to see when the store is closed."
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="preOrderMessage"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Pre Order Message"
              fullWidth
              helperText="Specify what message you want customers to see for pre-order when the store is closed."
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="reservationClosedMessage"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Reservation Close Message"
              fullWidth
              helperText="Specify what message you want customers to see when the reservation is closed."
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="reservationSubmitMessage"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Reservation Submit Message"
              fullWidth
              helperText="Specify what message you want customers to see when they submit reservation request."
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="reservationHeaderMessage"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Reservation Header Message"
              fullWidth
              helperText="Specify what message you want customers to see in the header of the reservation screen."
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Submit
        </Button>
      </Grid>
      {message && (
        <Grid item xs={12}>
          <Typography color={isSuccess ? "primary" : "error"}>
            {message}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default GeneralSettingsTab;

import React, {useState, useEffect} from "react";
import {useImmer} from "use-immer";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";

import LoadingButton from '@mui/lab/LoadingButton';
import {Day} from "./Day";
import {AddHour} from "./AddHour";
import {Hour} from "./Hour";

export function Calendar({getCalendar, updateCalendar}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [calendar, setCalendar] = useImmer([]);

  useEffect(() => {
    getCalendar()
    .then(x => {
      setCalendar(x.days);
      setError(false);
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }, []);

  const handleUpdateCalendar = () => {
    setLoading(true);
    updateCalendar(calendar)
      .then(() => setError(false))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  const handleAddHour = (target) => {
    setCalendar((draft) => {
      const day = draft.find(x => x.nameOfDay === target.nameOfDay);
      if (!day) {
        return;
      }
      const [sh, sm] = target.open.split(":");
      const [eh, em] = target.close.split(":");
      day.openHours.push({
        start: {hour: sh, minute: sm},
        end: {hour: eh, minute: em}
      });
      day.isOpen = true;
    });
  };

  const handleDeleteHour = (target) => {
    setCalendar((draft) => {
      const day = draft.find(x => x.nameOfDay === target.nameOfDay);
      if (!day) {
        return;
      }
      const index = day.openHours.findIndex(
        x => target.hour.start.hour === x.start.hour
          && target.hour.start.minute === x.start.minute
          && target.hour.end.hour === x.end.hour
          && target.hour.end.minute === x.end.minute
      );
      if (index === -1) {
        return;
      }
      day.openHours.splice(index, 1);
    });
  };

  const handleToggleDay = (ev) => {
    setCalendar(draft => {
      const day = draft.find(x => x.nameOfDay === ev.nameOfDay);
      if (!day) {
        return;
      }
      day.isOpen = ev.checked;
      if (!ev.checked) {
        day.openHours = [];
      }
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="70vh"
    >
      {loading && <CircularProgress color="inherit"/>}
      {error && <Alert severity="error">Could not load/update the calendar — something bad happend!</Alert>}
      {
        calendar.length > 0 && (
          <Grid container spacing={2}>
            {calendar.map(day => (
              <React.Fragment key={day.nameOfDay}>
                <Grid container item sx={{p: '15px', mt: '15px', ml: '5px', mr: '5px'}}>
                  <Grid xs={4} container item direction="row" justifyContent="space-between" alignItems="center">
                    <Day day={day} onChange={handleToggleDay}/>
                  </Grid>
                  <Grid xs={8} item container direction="column" alignItems="center">
                    {
                      day.openHours.map((hour, idx) => (
                        <Hour key={idx} day={day} hour={hour} onDelete={handleDeleteHour}/>
                      ))
                    }
                    <AddHour day={day} onAdd={handleAddHour}/>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item>
              <LoadingButton
                sx={{mt: '10px', ml: '10px', mb: '10px'}}
                color="primary"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveOutlinedIcon/>}
                variant="contained"
                onClick={handleUpdateCalendar}
              >
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        )
      }
    </Box>
  );
}
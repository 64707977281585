import React, { useEffect, useState, useRef } from "react";

import {
  Tabs,
  Tab,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from 'react-query';

import axios from "app/common/axios";
import { Topbar } from "app/common/Topbar";
import { config } from "app/common/config";
import GeneralSettingsTab from "./GeneralSettingsTab";
import ServicesTab from "./ServicesTab";
import PaymentTypesTab from "./PaymentTypesTab";
import StoreHoursTab from "./StoreHoursTab";
import ReservationHoursTab from "./ReservationHoursTab";
import AddressTab from "./AddressTab";

import "./Settings.css";

const getSettings = () => axios.get(`${config.apiUrl}/stores/default/settings/backoffice`).then(res => res.data);
const saveSettings = (data) => axios.post(`${config.apiUrl}/stores/default/settings`, data).then(res => res.data);

export function Settings() {
  const queryClient = useQueryClient();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef(null);

  const [tabValue, setTab] = useState(0);
  const [message, setMessage] = useState('');

  const { handleSubmit, control, watch, reset, formState: { errors }, setValue } = useForm({
    defaultValues: {
      timezone: '',
      isGuestOrderActive: false,
      isReservationActive: false,
      isCollectionActive: false,
      isDeliveryActive: false,
      isCashActive: false,
      isCardActive: false,
      isCashDefault: false,
      isCardDefault: true,
      isCollectionDefault: true,
      isDeliveryDefault: false,
      allowPreOrder: false,
      preOrderMessage: '',
      collectionWaitingTime: '',
      deliveryWaitingTime: '',
      storeCloseMessage: '',
      reservationSubmitMessage: '',
      reservationClosedMessage: '',
      reservationHeaderMessage: '',
    }
  });

  const { isLoading, isError, isSuccess, data, error } = useQuery('settings', getSettings);

  const { mutate, isLoading: isSaving, isError: isSaveError, isSuccess: isSaveSuccess, error: saveError } = useMutation(saveSettings, {
    onSuccess: () => queryClient.invalidateQueries("settings")
  });

  useEffect(() => {
    if (headerRef.current) {
      let height = matches ? 220 : 150;
      setHeaderHeight(headerRef.current.clientHeight + height);
    }
  }, [headerRef.current, matches]);

  useEffect(() => {
    if (isSuccess && data) {
      reset({
        timezone: data.timezone,
        isGuestOrderActive: data.isGuestOrderActive,
        isReservationActive: data.isReservationActive,
        isCollectionActive: data.isCollectionActive,
        isDeliveryActive: data.isDeliveryActive,
        allowPreOrder: data.allowPreOrder,
        isCashActive: data.isCashActive,
        isCardActive: data.isCardActive,
        isCashDefault: data.isCashDefault,
        isCardDefault: data.isCardDefault,
        isCollectionDefault: data.isCollectionDefault,
        isDeliveryDefault: data.isDeliveryDefault,
        collectionWaitingTime: data.collectionWaitingTime,
        deliveryWaitingTime: data.deliveryWaitingTime,
        storeCloseMessage: data.storeCloseMessage,
        reservationSubmitMessage: data.reservationSubmitMessage,
        reservationClosedMessage: data.reservationClosedMessage,
        reservationHeaderMessage: data.reservationHeaderMessage,
        preOrderMessage: data.preOrderMessage
      });
    }
  }, [data, isSuccess, reset]);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  if (isSaveError) {
    setMessage('Error saving settings.');
  }

  if (isSaveSuccess) {
    setMessage('Settings saved successfully.');
  }

  const onSubmit = (formData) => mutate({
    ...formData,
    collectionTime: formData.collectionWaitingTime,
    deliveryTime: formData.deliveryWaitingTime
  });

  const handleTabChange = (_, newValue) => setTab(newValue);

  return (
    <div className="settings">
      <Topbar title="Settings" ref={headerRef} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab label="Service" />
              <Tab label="General" />
              <Tab label="Payment" />
              <Tab label="Store Hours" />
              <Tab label="Reservation Hours" />
              <Tab label="Address" />
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ maxHeight: `calc(100vh - ${headerHeight}px)`, overflowY: "auto", p: '10px' }}>
              <ServicesTab
                tabValue={tabValue}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                isSuccess={isSaveSuccess}
                message={message}
                isSaving={isSaving}
              />
              <GeneralSettingsTab
                tabValue={tabValue}
                control={control}
                data={data}
                errors={errors}
                isSuccess={isSaveSuccess}
                message={message}
                isSaving={isSaving}
              />
              <PaymentTypesTab
                tabValue={tabValue}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                isSuccess={isSaveSuccess}
                message={message}
                isSaving={isSaving}
              />
              <StoreHoursTab tabValue={tabValue} />
              <ReservationHoursTab tabValue={tabValue} />
              <AddressTab tabValue={tabValue} />
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

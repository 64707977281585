import get from 'lodash-es/get';
import angular from "angular";

function ChangeAddressDialogController($mdDialog, orderSvc, user, orderId, address) {
    const vm = this;
    vm.errors = '';
    vm.address = {
        houseNo: address.houseNo,
        flatNo: address.flatNo,
        building: address.building,
        road: address.road,
        town: address.town,
        postCode: address.postCode
    };

    vm.cancel = cancel;
    vm.shouldUpdateDefaultAddress = !user.isGuest();
    vm.allowUpdateDefaultAddress = !user.isGuest();
    vm.changeAddress = changeAddress;
    vm.canChangeAddress = canChangeAddress;

    function canChangeAddress(form) {
        return form.$valid;
    }

    function cancel() {
        $mdDialog.cancel();
    }

    function changeAddress(form) {
        vm.errors = '';
        if (!form.$valid) return false;
        orderSvc.updateAddress({
            ...vm.address,
            shouldUpdateDefault: vm.shouldUpdateDefaultAddress,
            orderId
        })
        .then(order => $mdDialog.hide(order))
        .catch(err => {
            vm.errors = get(err, 'data.message', 'Could not update address. Unknown error occured');
        });
    }
}

export default [
    "$mdDialog",
    "orderSvc",
    "security.currentUser",
    "orderId",
    "address",
    ChangeAddressDialogController
];

import React from "react";
import {config} from "../common/config";
import axios from '../common/axios';

import {Calendar} from "./Calendar";

const getCalendar = () => axios.get(`${config.apiUrl}/stores/default/reservation/calendar`).then(res => res.data);
const updateCalendar = (data) => axios.put(`${config.apiUrl}/stores/default/reservation/calendar`, {weekSchedule: data}).then(res => res.data);

export function ReservationCalendar() {
  return (
    <Calendar getCalendar={getCalendar} updateCalendar={updateCalendar} />
  );
}

import React, {useState} from "react";
import CheckIcon from '@mui/icons-material/Check';

import {
  IconButton,
  Grid,
  NativeSelect,
  FormControl,
  InputLabel,
} from "@mui/material";

const _hours = [...Array(24).keys()];
const _minutes = [...Array(60).keys()].filter(x => x % 15 === 0);
const HOURS = _hours.map(h => _minutes.map(m => `${String(h).padStart(2, "0")}:${String(m).padStart(2, "0")}`)).flatMap(x => x);


function Dropdown({label, hour, onChange}) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <NativeSelect
        defaultValue={hour}
        onChange={handleChange}
      >
        {
          HOURS.map(h => (
            <option key={h} value={h}>{h}</option>
          ))
        }
      </NativeSelect>
    </FormControl>
  );
}

export function AddHour({day, onAdd}) {
  const [openHour, setOpenHour] = useState(HOURS[0]);
  const [closeHour, setCloseHour] = useState(HOURS[0]);

  const handleOpenChange = (x) => setOpenHour(x);
  const handleCloseChange = (x) => setCloseHour(x);
  const handleAddHour = () => onAdd({nameOfDay: day.nameOfDay, open: openHour, close: closeHour});

  return (
    <Grid container item direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{mt: '10px'}}>
      <Grid item>
        <Dropdown label="Opens At" hour={openHour} onChange={handleOpenChange}/>
      </Grid>
      <Grid item>
        <Dropdown label="Closes At" hour={closeHour} onChange={handleCloseChange}/>
      </Grid>
      <Grid item>
        <IconButton aria-label="add hours" onClick={handleAddHour}>
          <CheckIcon/>
        </IconButton>
      </Grid>
    </Grid>
  );
}
import get from 'lodash-es/get';
import loginDialogView from "./view/login-dialog.html";
import modifierDialogView from "./view/modifer-dialog.html";
import updatePostcodeModal from './view/update-postcode-modal.html';


function OrderController(
    order,
    orderService,
    $state,
    $mdDialog,
    currentUser,
    oauth,
    settings,
    $mdSidenav,
    $pusher,
    $scope,
    $mdToast
) {

    const vm = this;

    vm.selectedGroup = null;
    vm.groups = [];
    vm.selectGroup = selectGroup;
    vm.menus = [];
    vm.order = order;

    vm.placeOrder = placeOrder;
    vm.canPlaceOrder = canPlaceOrder;
    vm.login = login;
    vm.signOut = signOut;
    vm.currentUser = currentUser;
    vm.settings = settings;

    vm.showAllergyInfo = showAllergyInfo;

    vm.toggleMenuGroupSidenav = () => {
        $mdSidenav("menu-group-sidenav").toggle();
    };
    vm.toggleCartSidenav = () => {
        $mdSidenav("menu-cart-sidenav").toggle();
    };

    vm.addLine = (ev, menu, quantity) => {
        if (!hasModifier(menu)) {
            vm.order.addLine(menu, quantity);
            return;
        }
        $mdDialog.show({
            controller: "order.SelectModifierDialogController",
            template: modifierDialogView,
            controllerAs: "vm",
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            locals: {
                menu: menu
            }
        })
        .then(modifiers => vm.order.addLine(menu, quantity, modifiers))
        .catch((err) => showToast(get(err, 'data.message', 'Could not add line; try again.')));
    };
    
    vm.removeModifier = (l, m) => {
        vm.order.removeModifier(l, m)
            .catch(err => showToast(get(err, 'data.message', 'Could not remove modifier; try again.')));
    };

    vm.reduceQuantity = (l) => {
        vm.order.reduceQuantity(l)
            .catch(err => showToast(get(err, 'data.message', 'Could not reduce quantity; try again.')));
    };

    init();

    function hasModifier(menu) {
        return (menu.modifierLevels.length > 0
            && menu.modifierLevels[0].modifiers.length > 0);
    }

    function signOut() {
        oauth.signOut();
    }

    function login(ev) {
        showLoginDialog(ev);
    }

    function showLoginDialog(ev) {
        return $mdDialog.show({
            controller: "order.LoginDialogController",
            template: loginDialogView,
            controllerAs: "vm",
            locals: {
                "settings": settings
            },
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            fullscreen: false
        });
    }

    function checkout() {
        $state.go("app.checkout", {orderId: vm.order.id});
    }

    function placeOrder(ev) {
        if (vm.currentUser.isLoggedIn()) {
            return checkout(ev);
        }

        return showLoginDialog(ev)
            .then(() => checkout(ev));
    }

    function showAllergyInfo(ev) {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(angular.element(document.body)))
                .clickOutsideToClose(true)
                .title("Allergy or dietary requirements")
                .htmlContent(
                    "If you have an allergy that could harm your health or any other dietary requirements, we strongly advise you to <b>contact the restaurant directly before you place your order</b>.")
                .ariaLabel("Allergy Info Dialog")
                .ok("Got it!")
                .targetEvent(ev)
        );
    }

    function init() {
        orderService
            .getAllGroups()
            .then((groups) => {
                vm.groups = [].concat(groups);
                selectGroup(groups[0]);
            });
    }

    function showToast(message) {
        const toast = $mdToast.simple()
            .textContent(message)
            .action('ok')
            .capsule(true)
            .highlightAction(true)
            .highlightClass('md-warn')
            .theme('alert')
            .hideDelay(10000);

        return $mdToast.show(toast);
    }

    vm.preOrderMessageShown = false;
    vm.hasAddressError = () => vm.order.errors.some(x => x.type === 'ADDRESS');

    vm.showPostCodePrompt = function (ev) {
        ev && ev.preventDefault();
        $mdDialog.show({
            controller: "order.UpdatePostcodeController",
            template: updatePostcodeModal,
            controllerAs: "vm",
            locals: {
                "settings": settings,
                "order": vm.order
            },
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            fullscreen: false
        }).then(() => this.order.refresh());
    };

    
    $scope.$watchGroup([
        () => vm.order.isStoreOpen,
        () => vm.order.allowPreOrder,
        () => vm.order.totalItem,
    ], ([isStoreOpen, allowPreOrder, totalItem], ov) => {
        if (!isStoreOpen && allowPreOrder && totalItem <= 0) {
            return showPreOrderMessage();
        }
    });

    $scope.$watchGroup([
        () => vm.order.needPostCode,
        () => vm.order.totalItem
    ], ([needPostCode, totalItem], oldVal) => {
        if (needPostCode === true && totalItem > 0) {
            return vm.showPostCodePrompt();
        }
    });

    function showPreOrderMessage(ev) {
        return $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.body))
                .clickOutsideToClose(false)
                .title("Pre-order now!")
                .textContent(settings.preOrderMessage)
                .ariaLabel("Restaurant Closed")
                .ok("Pre-order")
                .targetEvent(ev)
        );
    }

    function canPlaceOrder() {
        return vm.order.errors.length <= 0;
    }

    function selectGroup(group) {
        vm.selectedGroup = group;
        orderService
            .getMenusByGroup(vm.selectedGroup.id)
            .then(menus => {
                vm.menus = menus;
            });

        $mdSidenav("menu-group-sidenav").close();
    }
}

export default [
    "order",
    "order.orderService",
    "$state",
    "$mdDialog",
    "security.currentUser",
    "security.oauth",
    "settings",
    "$mdSidenav",
    "$pusher",
    "$scope",
    "$mdToast",
    OrderController
];